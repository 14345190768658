body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

.loading {
  position: fixed;
  z-index: 99999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.3), rgba(0, 0, 0, 0.3));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.3),
    rgba(0, 0, 0, 0.3)
  );
}

.width-40 {
  width: 40%;
}

.width-70 {
  width: 70%;
  text-align: right;
}

.header-logo-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.logo-link-a {
  display: inline-flex;
  width: 125px;
  height: 40px;
  margin-right: 6px;
}

.logo-link-a img {
  width: 100%;
  height: 100%;
}

.css-17dcqu8-MuiTypography-root-MuiLink-root,
.css-1oyo6v6 {
  display: flex;
  align-items: center;
}

.css-i4bv87-MuiSvgIcon-root,
.css-vubbuv {
  font-size: 20px !important;
  width: 1.5rem !important;
}
.footer-left {
  width: 65%;
}
.footer-right {
  width: 35%;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .text-align {
    text-align: center;
  }
  .header-logo-link {
    flex-direction: column-reverse;
  }
  .width-40,
  .width-70 {
    width: 100%;
    text-align: center;
  }
  .css-204u17-MuiDataGrid-main,
  .css-opb0c2 {
    height: 600px;
    overflow: scroll !important;
  }
  .css-b1pcwn-MuiFormControl-root-MuiTextField-root,
  .css-1xa20hz {
    width: 100% !important;
  }

  .css-f3jnds-MuiDataGrid-columnHeaders,
  .css-cfdg34 .MuiDataGrid-row,
  .css-1grl8tv,
  .MuiDataGrid-virtualScroller .css-1grl8tv,
  .MuiDataGrid-virtualScrollerContent
    .MuiDataGrid-virtualScrollerContent--overflowed,
  .css-1inm7gi,
  .css-um056b,
  .css-1nitb7c,
  .css-1s0hp0k-MuiDataGrid-columnHeadersInner,
  .css-1w5m2wr-MuiDataGrid-virtualScroller,
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone,
  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-row {
    display: block !important;
  }

  .css-1kwdphh-MuiDataGrid-virtualScrollerContent,
  .css-0 {
    width: 100% !important;
  }

  .css-1w5m2wr-MuiDataGrid-virtualScroller,
  .css-1grl8tv {
    position: absolute !important;
    top: -56px;
  }

  .css-f3jnds-MuiDataGrid-columnHeaders,
  .css-1nitb7c,
  .css-um056b,
  .css-1s0hp0k-MuiDataGrid-columnHeadersInner {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  /* .css-f3jnds-MuiDataGrid-columnHeaders {
    border: 1px solid #ccc;
  } */

  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-row:nth-child(odd),
  .css-cfdg34 .MuiDataGrid-row:nth-child(odd) {
    background-color: #ccc;
  }

  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-cell,
  .css-cfdg34 .MuiDataGrid-cell {
    font-size: 14px !important;
    max-width: 100% !important;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding: 10px !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    text-align: left !important;
    white-space: normal;
  }

  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-cellContent,
  .css-cfdg34 .MuiDataGrid-cellContent {
    overflow: unset !important;
  }

  /* .MuiDataGrid-cellContent:before {
    padding-right: 10px;
    white-space: nowrap;
  } */

  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(1):before,
  .css-cfdg34 .MuiDataGrid-cell:nth-of-type(1):before {
    content: "Nơi nhận";
    font-weight: 700;
  }
  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(2):before,
  .css-cfdg34 .MuiDataGrid-cell:nth-of-type(2):before {
    content: "Mã chứng từ đối tác";
  }
  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(3):before,
  .css-cfdg34 .MuiDataGrid-cell:nth-of-type(3):before {
    content: "Ghi chú nội dung";
  }
  /* .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(4):before,
  .css-cfdg34 .MuiDataGrid-cell:nth-of-type(4):before {
    content: "Người tạo";
  }
  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(5):before,
  .css-cfdg34 .MuiDataGrid-cell:nth-of-type(5):before {
    content: "Thời gian tạo";
  } */
  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(4):before,
  .css-cfdg34 .MuiDataGrid-cell:nth-of-type(4):before {
    content: "Thời gian giao dự kiến";
  }
  .css-15s1wsm-MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(5):before,
  .css-cfdg34 .MuiDataGrid-cell:nth-of-type(5):before {
    content: "";
  }
}
@media only screen and (max-width: 760px) {
  .footer-left,
  .footer-right {
    width: 100%;
  }
}
